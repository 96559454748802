import React from 'react'
import styled from 'styled-components'
import fizzpackLogo from '../images/fizzpack.svg'
import instagramLogo from '../images/instagram.svg'


const menuItems = [
    {
        "text": "home",
        "url": "/"
    },
    {
        "text": "Portfolio",
        "url": "/portfolio"
    },
    {
        "text": "Animation Services",
        "url": "/animation-services"
    },
    {
        "text": "How we work",
        "url": "/how-we-work"
    },
    {
        "text": "Contact Us",
        "url": "/contact-us"
    },
    {
        "text": "Privacy Statement",
        "url": "/policy-pages/privacy-statement"
    },
    {
        "text": "Cookie Policy",
        "url": "/policy-pages/cookie-policy"
    }
]

const FooterWrapper = styled.footer`
    background-color: #1e1e1e;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    

    img {
        max-width: 200px;
        display: block;
        margin: 20px auto;
    }

    .footer-social {
        display: grid;
        align-items: flex-start;
        justify-items: center;
        max-width: 400px;
        margin-right: 0;
        margin-top: 20px;
    }

    .footer-social a {
        text-align: center;
        display: grid;
        grid-template-columns: 1fr 50px;
        align-items: center;
        background: #272727;
        border-radius: 20px;
    }

    .footer-social a:hover,
    .footer-social a:focus {
        background: #000;
        box-shadow: 0 0 40px #000;
        transition: all 0.3s;
    }

    .footer-social a p {
        color: #686868;
        font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
        font-size: 17px;
        font-weight: bold;
        margin-right: 10px;
        margin-left: 10px;
    }

    .footer-social img {
        max-width: 40px;
        margin-right: 20px;
    }

    h4 {
        font-family: 'Zing';
        margin-top: 20px;
        margin-left: 0;
        margin-bottom: 11px;
        color: #fa2b69;
        font-size: 16px;
    }

    ul li {
        list-style-type: none;
    }

    ul li a {
        font-size: 18px;
        padding: 4px 4px 4px 0;
        display: block;
    }

    @media screen and (min-width: 480px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

const Footer = (props) => {

    const MenuItems = (props) => {

        const items = props.menuItems
        const itemsMapped = items.map((item) => (
                
                <li key={item.url}>
                    <a href={item.url}>{item.text}</a>
                </li>
    
             )
        )
        return <ul>{itemsMapped}</ul>
    }

    return(

        <FooterWrapper>
            <img src={fizzpackLogo} alt="fizzpack logo" />

            <div className="footer-social">
                <a href="http://www.instagram.com/fizzpack" target="_blank" rel="noopener noreferrer" >
                <p>Join us on Instagram</p>
                <img src={instagramLogo} alt="instagram logo" />
                </a>
            </div>

            <div className="footer-nav">
            <div>
            <h4>Navigation</h4>

            <MenuItems menuItems={menuItems}/>
            </div>
            </div>


        </FooterWrapper>

    )
}

export default Footer;