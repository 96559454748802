import React from 'react'
import favicon from '../images/favicon.ico'
import { Helmet } from 'react-helmet'
import coverImage from '../images/cover.jpg'

const HelmetInfo = () => {
    return (
        <Helmet>
        <link rel="icon" href={favicon} />
        <title>Fizzpack Animated Advertising Shorts</title>
        <meta name="description" content="We're an advertising and animation company. Specializing in bold, colourful design and short animation." />
        <meta name="image" content={coverImage} />
        {/* OpenGraph tags */}
        <meta property="og:url" content={'https://www.fizzpack.com'} />
        {/* {isBlogPost ? <meta property="og:type" content="article" /> : null} */}
        <meta property="og:title" content={'Fizzpack Animated Advertising Shorts'} />
        <meta property="og:description" content={"We're an advertising and animation company"} />
        <meta property="og:image" content={coverImage} />
        <meta property="article:publisher" content="https://www.facebook.com/fizzpack" />
        <meta property="og:image:width" content="2000" />
        <meta property="og:image:height" content="666" /> 
    </Helmet>
    );
}

export default HelmetInfo;