import React, { useState } from 'react';
import styled from 'styled-components'
import { Link } from "gatsby"
import FPicon from '../images/fp.svg'


const Nav = styled.nav`
    display: grid;
    grid-template-columns: 70px 70px 1fr ;

    @media screen and (max-width: 900px) {
        grid-template-columns: 70px 1fr 70px;
    }

    grid-template-rows: 113px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .fp-mini-icon {
        max-width: 23px;
        position: relative;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
`

const List = styled.ul`
    text-align: center;
    color: white;
    font-size: 1em;
    margin-top: 20px;
`

const ListItem = styled.li`
    display: none;
`

const OuterMenu = styled.div`
    position: relative;
    z-index: 4;
`

const CheckBoxToggle = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0;

    @media screen and (min-width: 900px) {
        display: none;
    }

`

const Hamburger = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 30px;
    height: 30px;
    padding: 0.5em 1em;
    border-radius: 0 0.12em 0.12em 0;
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 900px) {
        display: none;
    }
`

const HamburgerLines = styled.div`
    position: relative;
    flex: none;
    width: 100%;
    height: 4px;
    background: #fff;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;

    @media screen and (min-width: 900px) {
        display: none;
    }

    &:after, 
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 0;
        width: 100%;
        height: 4px;
        background: inherit;
        transition: all 0.4s ease;      
    }
    &:after {
        width: 60%;
        top: 10px;
    }

    &.toggled-on {
        transform: rotate(135deg);
        &:after {
            top: 0;
            transform: rotate(90deg);
            opacity: 0;
        }
        &:before {
            top: 0;
            transform: rotate(90deg);
        }
        
    }

    &.toggled-off {
        transform: rotate(0);

    }
`

const MenuWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fefefe;
    transform-origin: center;
    &.toggled-off { 
        visibility: hidden;
        pointer-events: none;
        border-radius: 100% 20% 100% 20%;
        transform: scale(0);
        transform-origin: center;
        a {
            opacity: 0;
        }
    }
    &.toggled-on { 
        visibility: visible;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(160deg, #f0a354, #e43f35 50%, #c8409e);
        border-radius: 0;
        transition: all 0.4s ease;
        transform: scale(1.2);
        transform-origin: center;
        a {
            opacity: 1;
        }
    }

    ul li {
        list-style-type: none;
        padding: 0;
    }

    ul li a {
        padding: 0;
        margin: 1em;
        font-size: 1.2em;
        display: block;
        color: #fefefe;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        font-size: 2rem;
    }

    ul li a:after {
        content: '';
        position: relative;
        z-index: 1;
        bottom: -0.15em;
        left: 0;
        width: 0;
        height: 2px;
        background: #e5e5e5;
        transition: width 0.4s ease;
        display: block;
    }

    ul li a:hover:after {
        width: 100%;
    }    
`

const menuItems = [
    {
        "text": "home",
        "url": "/"
    },
    {
        "text": "Portfolio",
        "url": "/portfolio"
    },
    {
        "text": "Animation Services",
        "url": "/animation-services"
    },
    {
        "text": "How we work",
        "url": "/how-we-work"
    },
    {
        "text": "Contact Us",
        "url": "/contact-us"
    }
]

const DesktopMenu = styled.div`
    @media screen and (max-width: 900px) {
        display: none;
    }

    ul {
        display: flex;
        justify-content: end;
        float: right;

        li {
            padding: 20px;
            list-style-type: none;
            font-size: 24px;
        }

        a:hover { opacity: 0.6 }
    }
`

const MenuItemsList = (props) => {

    const items = props.menuItems
    const itemsMapped = items.map((item) => (
            
            <li key={item.url}>
                <Link to={item.url}>{item.text}</Link>
            </li>

         )
    )
    return <ul>{itemsMapped}</ul>
}

const Menu = (props) => {

    const toggleMenu = (e) => {
        e.preventDefault()
        setIsOpen(!isOpen)
    }
    
    const [isOpen, setIsOpen] = useState(false)
    
    return(

    <Nav>
        <Link to="/">
            <img className="fp-mini-icon" src={FPicon} alt="Fizzpack" />
        </Link>

        <List>
            <ListItem>Motion Design :</ListItem>
            <ListItem>Animation :</ListItem>
            <ListItem>Video</ListItem>
        </List>

        <OuterMenu>

            <CheckBoxToggle type="checkbox" onClick={toggleMenu} />

            <Hamburger>
              <HamburgerLines className={isOpen ? "toggled-on" : "toggled-off"} />
            </Hamburger>

            <MenuWrapper {...props} className={isOpen ? "toggled-on" : "toggled-off"} >
                <MenuItemsList menuItems={menuItems} />
            </MenuWrapper>

            <DesktopMenu>
                <MenuItemsList menuItems={menuItems} />
            </DesktopMenu>

        </OuterMenu>
        
    </Nav>
)}

export default Menu;